<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-alert
        :value="'2024-02' === monthOf"
        color="info"
        icon="info"
      >
        For February 2024, calculations start from 24th January 2024.
      </v-alert>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          lazy
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2024-02"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          placeholder="Search"
          clearable
        />
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="headers"
          slot-scope="props"
        >
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="headerClass(header)"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon
                v-if="header.sortable"
                small
              >
                arrow_upward
              </v-icon>
            </th>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-center">
            {{ props.item.tier }}
          </td>
          <td class="text-xs-left">
            {{ props.item.code }}
          </td>
          <td
            v-if="hasExpiry"
            class="text-xs-left"
          >
            {{ props.item.expiry }}
          </td>
          <td class="text-xs-center">
            {{ props.item.size }}
          </td>
          <td
            v-if="hasRemark"
            class="text-xs-left"
          >
            {{ props.item.remark }}
          </td>
          <td class="text-xs-right">
            {{ props.item.incentive }}
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td v-if="hasExpiry">
&nbsp;
            </td>
            <td>&nbsp;</td>
            <td v-if="hasRemark">
&nbsp;
            </td>
            <td>&nbsp;</td>
            <td class="text-xs-right primary--text">
              Total UNL35 Campaign:
            </td>
            <td class="text-xs-right primary--text">
              {{ totalUNLIncentive }}
            </td>
          </tr>
          <tr v-if="hasIncentive">
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td v-if="hasExpiry">
&nbsp;
            </td>
            <td>&nbsp;</td>
            <td v-if="hasRemark">
&nbsp;
            </td>
            <td>&nbsp;</td>
            <td class="text-xs-right primary--text">
              Total UNL35 SSP:
            </td>
            <td class="text-xs-right primary--text">
              {{ totalIncentive }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const reportTitle = 'UNL35 Campaign'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2019, 6, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'Campaigns', disabled: false, to: '/campaigns',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      hasExpiry: false,
      hasRemark: false,
      hasUNLIncentive: false,
      hasIncentive: false,
      items: [
      ],
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: null,
      noResultText: 'No data that fits that criteria.',
      pagination: {
        descending: true,
        sortBy: 'name',
      },
      search: '',
      title: reportTitle,
      totalIncentive: 0,
      totalUNLIncentive: 0,
    }
  },
  computed: {
    headers: function () {
      const headers = [
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'Date',
          value: 'date',
        },
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'Msisdn',
          value: 'msisdn',
        },
        {
          align: 'text-xs-center',
          sortable: true,
          text: 'Tier',
          value: 'tier',
        },
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'SSP',
          value: 'code',
        },
      ]

      this.hasExpiry && headers.push({
        align: 'text-xs-left',
        sortable: true,
        text: 'Expiry',
        value: 'expiry',
      })

      headers.push({
        align: 'text-xs-center',
        sortable: true,
        text: 'Size',
        value: 'size',
      })

      this.hasRemark && headers.push({
        align: 'text-xs-left',
        sortable: true,
        text: 'Remark',
        value: 'remark',
      })

      headers.push({
        align: 'text-xs-right',
        sortable: true,
        text: 'Incentive',
        value: 'incentive',
      })

      return headers
    },
  },
  watch: {
    monthOf: function (val) {
      this.getSspIncentive(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    getSspIncentive (month) {
      const params = createGetParams({
        month: month,
      })
      this.loading = true
      this.$rest.get('getUnl35Campaign.php', params)
        .then(function (response) {
          this.items = response.data.entries
          response.data.entries.forEach(item => {
            if (item.expiry) this.hasExpiry = true
            if (item.remark) this.hasRemark = true
          })
          this.totalIncentive = response.data.incentive
          this.totalUNLIncentive = response.data.unlincentive
          if (response.data.unlincentive > 0) {
            this.hasUNLIncentive = true
          } else {
            this.hasUNLIncentive = false
          }
          if (response.data.incentive > 0) {
            this.hasIncentive = true
          } else {
            this.hasIncentive = false
          }
          this.noDataText = "There's nothing to show."
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message + ': ' + error.response.data
          this.loading = false
          this.hasUNLIncentive = false
          this.hasIncentive = false
        })
    },
    headerClass (header) {
      const headerClass = ['column']
      header.align && headerClass.push(header.align)
      header.sortable && headerClass.push('sortable')
      header.sortable && (this.pagination.descending
        ? headerClass.push('desc') : headerClass.push('asc'))
      header.sortable && header.value === this.pagination.sortBy &&
          headerClass.push('active')

      return headerClass
    },
  },
}
</script>
